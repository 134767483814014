@import './font.css';

body {
  background: linear-gradient(to top, #99ccff 0%, #6699ff 100%);
  font-family: 'Nunito';
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-weight: 400;
}

.cookiebanner-close {
  color: forestgreen;
}
