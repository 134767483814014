@font-face {
  font-family: 'Nunito';
  font-weight: 400;
  src: local('Nunito'),
    url('./assets/fonts/Nunito/Nunito-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-weight: 600;
  src: local('Nunito'),
    url('./assets/fonts/Nunito/Nunito-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-weight: 700;
  src: local('Nunito'),
    url('./assets/fonts/Nunito/Nunito-Bold.ttf') format('truetype');
}
